import styled from 'styled-components'

export const Card = styled.div`
  background: ${(props) => props.theme.colors.background.secondary};
  padding: 15px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  section {
    margin-bottom: 10px;
  }
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h5 {
    margin-left: 10px;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 11px;
    flex: 1;
  }
`
