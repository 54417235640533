import useMutation, { Options } from 'use-mutation'

import { TestRun } from 'types'
import { useTrackFeature } from 'hooks/useTrackFeature'
import { useDatasource } from 'datasourceContext'

export const useRunTest = <Error>(options?: Options<number, TestRun, Error>) => {
  const { ds } = useDatasource()
  const trackFeature = useTrackFeature()

  const handleSuccess = (params: { data: TestRun; input: number }) => {
    trackFeature('start_test')
    options?.onSuccess?.(params)
  }

  return useMutation<number, TestRun, Error>((testId: number) => ds.runTest(Number(testId)), {
    ...options,
    onSuccess: handleSuccess,
  })
}
