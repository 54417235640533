import { dateTime, DateTime, Field, FieldType, TimeRange } from '@grafana/data'
import { isNumber } from 'lodash-es'

import { TimeSeriesItem, TimeSeriesValue, Value } from 'types'
import { isMultipleOf } from './predicate'

export const toPrecise = (val: number, precision = 2) => Number(Number(val).toFixed(precision))

export const responseTimeFormatter = (responseTimeMS: number) => {
  const precision = responseTimeMS > 10 ? 0 : 2
  const fixedNumber = Number(Number(responseTimeMS).toFixed(precision))

  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const separatorFormatter = (num: number, precision = 0, delimiter = ' ') => {
  return String(toPrecise(num, precision)).replace(/\B(?=(\d{3})+(?!\d))/g, delimiter)
}

export const vusFormmatter = (vus: number) => {
  if (vus === 0) {
    return `-`
  }

  if (vus === 1) {
    return `${vus} VU`
  }

  return `${separatorFormatter(vus)} VUs`
}

export const unitFormatter = (num: number, precision = 1) => {
  if (!isNumber(num)) {
    return num
  }

  if (num / 1000000 >= 1) {
    return isMultipleOf(1000000, num) ? `${num / 1000000}M` : `${toPrecise(num / 1000000, precision)}M`
  }

  if (num / 1000 >= 1) {
    return isMultipleOf(1000, num) ? `${num / 1000}K` : `${toPrecise(num / 1000, precision)}K`
  }

  return Math.ceil(num)
}

export const timeFormatter = (timeInSeconds = 0) => {
  const totalSeconds = Math.round(timeInSeconds)
  const seconds = Math.round(totalSeconds % 60)

  if (totalSeconds < 0) {
    return '-'
  }

  if (totalSeconds < 60) {
    return `${totalSeconds}s`
  }

  if (seconds > 0) {
    const minutes = Math.round((timeInSeconds - seconds) / 60)

    return `${minutes}min ${seconds}s`
  }

  return `${Math.round(totalSeconds / 60)}min`
}

export const createTimeRange = (from: DateTime | string, to: DateTime | string): TimeRange => ({
  from: dateTime(from),
  to: dateTime(to),
  raw: { from, to },
})

export const createTimeRangeOn = <T extends { [key: string]: unknown }>(key: string, arr: T[] = []): TimeRange => {
  const from = arr[0]?.[key] as DateTime
  const to = arr[arr.length - 1]?.[key] as DateTime

  return createTimeRange(from, to)
}

export const createTimeSeriesItem =
  (label: string, radix?: number) =>
  (props: Value): TimeSeriesItem =>
    Object.assign(
      { timestamp: new Date(props.timestamp) },
      { [label]: radix ? parseInt(props.value, radix) : props.value }
    )

export const createDataFrameFields = (
  values: TimeSeriesValue[] = [],
  config = {},
  radix?: number
): Array<Field<number, number[]>> => {
  return [
    {
      config,
      name: 'timestamp',
      type: FieldType.time,
      values: values.map((props) => new Date(props.timestamp).getTime()),
    },
    {
      config,
      name: 'value',
      type: FieldType.number,
      values: values.map((props) => (radix ? parseInt(props.value.toString(), radix) : props.value)),
    },
  ]
}
