import { useEffect, useState } from 'react'

import { useFetchPaginated } from './useFetch'
import { useDatasource } from 'datasourceContext'
import { TestRun, TestRuns } from 'types'
import { useActiveRuns } from './useActiveRuns'
import { mergeArraysByKey } from 'utils/mergeArrayByKey'

const updateRuns = (runsFlat: TestRun[], testId: number, activeRuns: TestRun[]) => {
  const runsFiltered = activeRuns.filter((run) => run.test_id === testId)
  return mergeArraysByKey(runsFlat, runsFiltered, 'id')
}

export const useRuns = (testId: number) => {
  const { ds } = useDatasource()
  const [runsFlat, setRunsFlat] = useState<TestRun[]>([])

  const getRunsKey = (pageIndex: number, previousPageData: TestRuns) => {
    if (previousPageData && previousPageData.meta.count < pageIndex + 1) {
      return null
    }

    return {
      id: testId,
      page: pageIndex + 1,
    }
  }

  const { data, size, loadNext, isLoading, isLoadingInitial } = useFetchPaginated(getRunsKey, ds.fetchRuns.bind(ds))
  const { data: activeRuns, refresh } = useActiveRuns()

  const flattenRuns = () => {
    if (!data) {
      return
    }

    setRunsFlat(data.flatMap((page) => page['k6-runs']))
  }

  const setActiveRuns = () => {
    if (!activeRuns) {
      return
    }

    setRunsFlat((runs) => updateRuns(runs, testId, activeRuns))
  }

  useEffect(flattenRuns, [data])
  useEffect(setActiveRuns, [activeRuns, testId])

  const pageCount = data?.[0]?.meta?.count || 1

  return {
    refresh,
    isLoading,
    isLoadingInitial,
    runs: runsFlat,
    loadNext: () => size < pageCount && loadNext(),
  }
}
