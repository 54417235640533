import {
  TestRun,
  TestRunStatus,
  TestRunStatusText,
  TestRunResultStatus,
  TestRunResultStatusText,
  TestRunProcessingStatus,
  K6TestRunStatusesActive,
  BadgeColors,
  BadgeIcons,
  FormattedRuns,
} from '../types'
import { DataFrame, FieldType, KeyValue, toDataFrame } from '@grafana/data'
import _ from 'lodash'
import { MAX_VALUE_EMPTY_BARS } from 'constants/index'

export const padEmptyBars = (runs: TestRun[], padLength: number) => {
  const emptyRunData = {
    created: null,
    run_status: null,
    result_status: 0,
  }

  return [...Array(padLength).fill(emptyRunData), ...runs]
}

export const getTestStatusText = (testRun: TestRun) => {
  if (isTestProcessing(testRun.processing_status)) {
    if (testRun.run_status === TestRunStatus.ABORTED_USER) {
      return `Stopping test`
    }

    if (testRun.run_status === TestRunStatus.FINISHED) {
      return `Finalizing test`
    }

    return `Processing result`
  }

  if (testRun.run_status === null) {
    return 'Not run'
  }

  return getTestRunResultText(testRun)
}

export const getTestRunColorString = (testRun: TestRun) => {
  if (canShowResultStatus(testRun)) {
    return getResultStatusColor(testRun.result_status)
  }

  return getRunStatusColor(testRun.run_status)
}

export const getTestRunBadgeIcon = (testRun: TestRun) => {
  if (isTestActive(testRun)) {
    return BadgeIcons.SPINNER
  }

  if (testRun.run_status === TestRunStatus.FINISHED && testRun.result_status === TestRunResultStatus.PASSED) {
    return BadgeIcons.CHECK
  }

  if (testRun.run_status === TestRunStatus.ABORTED_USER) {
    return BadgeIcons.MINUS_CIRCLE
  }

  return BadgeIcons.EXCLAMATION_TRIANGLE
}

export const isTestActive = (testRun: TestRun) => {
  return (
    testRun && (K6TestRunStatusesActive.includes(testRun.run_status) || isTestProcessing(testRun.processing_status))
  )
}

export const isTestAborted = (status: number) =>
  [
    TestRunStatus.ABORTED_USER,
    TestRunStatus.ABORTED_SYSTEM,
    TestRunStatus.ABORTED_SCRIPT_ERROR,
    TestRunStatus.ABORTED_THRESHOLD,
    TestRunStatus.ABORTED_LIMIT,
  ].includes(status)

const getResultStatusColor = (status: number) => {
  switch (status) {
    case TestRunResultStatus.PASSED:
      return BadgeColors.GREEN
    case TestRunResultStatus.FAILED:
      return BadgeColors.RED
    default:
      return BadgeColors.GRAY
  }
}

export const getRunStatusColor = (status: number) => {
  switch (status) {
    case TestRunStatus.FINISHED:
      return BadgeColors.GREEN
    case TestRunStatus.RUNNING:
      return BadgeColors.BLUE
    case TestRunStatus.INITIALIZING:
      return BadgeColors.PURPLE
    case TestRunStatus.VALIDATED:
      return BadgeColors.GRAY
    case TestRunStatus.ABORTED_USER:
      return BadgeColors.ORANGE
    case TestRunStatus.TIMED_OUT:
    case TestRunStatus.ABORTED_SYSTEM:
    case TestRunStatus.ABORTED_SCRIPT_ERROR:
    case TestRunStatus.ABORTED_THRESHOLD:
    case TestRunStatus.ABORTED_LIMIT:
      return BadgeColors.RED
    default:
      return BadgeColors.GRAY
  }
}

const canShowResultStatus = (testRun: TestRun) => {
  return (
    isTestDone(testRun) &&
    testRun.run_status !== TestRunStatus.ABORTED_USER &&
    testRun.run_status !== TestRunStatus.TIMED_OUT &&
    testRun.result_status === TestRunResultStatus.FAILED
  )
}

const getTestRunResultText = (testRun: TestRun) => {
  if (canShowResultStatus(testRun)) {
    return getResultStatusText(testRun.result_status)
  }

  return getRunStatusText(testRun.run_status)
}

const getResultStatusText = (status: number) => {
  return TestRunResultStatusText[status] || ''
}

const getRunStatusText = (status: number) => {
  return TestRunStatusText[status] || ''
}

const isTestProcessing = (processingStatus: number) => {
  return processingStatus === TestRunProcessingStatus.PROCESSING
}

export const isTestDone = (testRun: TestRun) => !isTestActive(testRun)

/**
 * The function goes through the ordered runs splits load times into arrays
 * identified by status and an int identifier. In this way we keep the order
 * of the data for the bar gauge.
 * @param runs
 * @returns result
 */
export const formatRunsForDataFrame = (runs: TestRun[]): KeyValue<FormattedRuns> => {
  let identifier = 0
  let prevStatusText = ''
  const result: KeyValue = {}

  const dateOptions: any = {
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }

  const max = getMaxLoadTimeValue(runs)

  for (let i = runs.length - 1; i >= 0; i--) {
    const status = getTestStatusText(runs[i]!)

    if (prevStatusText !== status) {
      identifier++
      prevStatusText = status
    }

    if (!result[status + identifier]) {
      result[status + identifier] = {
        loadTimes: [],
        dateStrings: [],
        status: {},
      }
    }

    result[status + identifier].loadTimes.push(getValue(runs[i]!, max))
    result[status + identifier].dateStrings.push(new Date(runs[i]!.created).toLocaleString(undefined, dateOptions))

    if (_.isEmpty(result[status + identifier].status)) {
      result[status + identifier].status = {
        run_status: runs[i]!.run_status,
        result_status: runs[i]!.result_status,
      }
    }
  }

  return result
}

export const loadFormattedRunsToDataframes = (formattedRuns: KeyValue<FormattedRuns>): DataFrame[] => {
  return Object.entries(formattedRuns).map((formattedRun) => {
    return toDataFrame({
      name: formattedRun[0],
      refId: formattedRun[0],
      fields: [
        { name: 'Date', type: FieldType.string, values: formattedRun[1].dateStrings },
        { name: 'LoadTime', type: FieldType.number, values: formattedRun[1].loadTimes },
      ],
    })
  })
}

export const isTestStarting = (testRun: TestRun) => {
  return [TestRunStatus.CREATED, TestRunStatus.VALIDATED, TestRunStatus.QUEUED, TestRunStatus.INITIALIZING].includes(
    testRun.run_status
  )
}

const getMaxLoadTimeValue = (runs: TestRun[]) => {
  return Math.max(...runs.map((run) => run.load_time)) || MAX_VALUE_EMPTY_BARS
}

const getValue = (run: TestRun, max: number) => {
  if (isTestActive(run)) {
    return max
  }

  // Use 0 as mininimal value for runs without load time to keep bar visible
  if (run.load_time === null) {
    return 0
  }

  return run.load_time
}
