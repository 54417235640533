import React, { useState } from 'react'
import { Tab, TabContent, TabsBar } from '@grafana/ui'

import { Overview, TestRun } from 'types'
import { useDatasource } from 'datasourceContext'
import { useOverview } from 'data/useOverview'
import { HttpTab } from './HttpTab'
import { ThresholdTab } from './ThresholdTab'
import { ChecksTab } from './ChecksTab'

export type BreakdownProps = { testRun: TestRun }

export const getCounter = (overview: Overview[] | undefined, valueKey: string) => {
  if (overview === undefined || overview.length === 0) {
    return null
  }

  return overview[0]?.[valueKey as keyof Overview] || 0
}

const tabs = [
  { name: 'HTTP', label: 'HTTP', component: HttpTab, valueKey: 'urls_hits_total' },
  { name: 'THRESHOLDS', label: 'THRESHOLDS', component: ThresholdTab, valueKey: 'thresholds_total' },
  { name: 'CHECKS', label: 'CHECKS', component: ChecksTab, valueKey: 'checks_hits_total' },
]

export const Breakdown = ({ testRun }: BreakdownProps) => {
  const { ds } = useDatasource()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const { data: overview } = useOverview(testRun)

  const ActiveTab = tabs[activeTabIndex]?.component!

  return (
    <div>
      <TabsBar>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            active={activeTabIndex === index}
            onChangeTab={() => setActiveTabIndex(index)}
            counter={getCounter(overview, tab.valueKey)}
          />
        ))}
      </TabsBar>
      <TabContent>
        <ActiveTab run={testRun} ds={ds} />
      </TabContent>
    </div>
  )
}
