import { useFetch } from './useFetch'
import { useDatasource } from 'datasourceContext'

type TestId = number | (() => number) | null

const getKey = (id: number | (() => number)) => {
  if (typeof id === 'function') {
    return id()
  }
  return id
}

export const useTest = (id: TestId) => {
  const { ds } = useDatasource()

  return useFetch(id ? [getKey(id), 'test'] : null, ds.fetchTest.bind(ds))
}
