import React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { PanelRenderer } from '@grafana/runtime'
import { LegendDisplayMode, PanelChrome } from '@grafana/ui'

import { K6DataSource } from 'datasource/datasource'
import { useTimeSeries } from 'data/useTimeSeries'
import { QueryType, TestRun } from 'types'
import { ClipBoard } from 'components/Clipboard'
import { createFieldConfig, createMetricTypes, createPanelData } from './ThresholdChart.utils'

interface Props {
  data: any
  ds: K6DataSource
  run: TestRun
}

const noop = () => {}

const panelOptions = {
  legend: {
    displayMode: LegendDisplayMode.List,
    placement: 'bottom',
    calcs: [],
  },
  graph: {},
  tooltipOptions: {
    mode: 'single',
  },
}

export const ThresholdChart = ({ run, ds, data }: Props) => {
  const { data: series = [] } = useTimeSeries(run, createMetricTypes(data.id))
  const panelData = createPanelData(series)
  const fieldConfig = createFieldConfig(data.value)

  return (
    <div>
      <ClipBoard
        name={ds.name}
        queryType={QueryType.THRESHOLDS}
        testRun={run}
        itemId={data.id.toString()}
        itemName={data?.name}
        itemValue={data?.value}
        fieldConfig={fieldConfig}
      />
      <AutoSizer disableHeight>
        {(size) => {
          return (
            <PanelChrome
              width={size.width}
              height={300}
              leftItems={[<PanelChrome.LoadingIndicator loading={false} onCancel={noop} key="loading-indicator" />]}
            >
              {(innerWidth, innerHeight) => (
                <PanelRenderer
                  title=""
                  pluginId="timeseries"
                  onOptionsChange={noop}
                  // onChangeTimeRange={(t) => onChangeTimeRange(toTimeRange(t, timeZone))}
                  width={innerWidth}
                  height={innerHeight}
                  // timeZone={timeZone}
                  data={panelData}
                  options={panelOptions}
                  fieldConfig={fieldConfig}
                />
              )}
            </PanelChrome>
          )
        }}
      </AutoSizer>
    </div>
  )
}
