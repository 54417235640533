import { GrafanaTheme2 } from '@grafana/data'
import { Icon, useStyles2 } from '@grafana/ui'
import { css } from 'emotion'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useBreadcrumbs } from 'breadcrumbsContext'

export const Breadcrumbs = () => {
  const { breadcrumbs } = useBreadcrumbs()
  const styles = useStyles2(getStyles)

  return (
    <>
      {breadcrumbs.map(({ name, path }, i) => (
        <span key={i} className={styles.breadcrumb}>
          {path && <NavLink to={path}>{name}</NavLink>}
          {!path && <span>{name}</span>}
          {i !== breadcrumbs.length - 1 && <Icon name="angle-right" />}
        </span>
      ))}
    </>
  )
}

export const getStyles = (theme: GrafanaTheme2) => {
  return {
    breadcrumb: css`
      font-size: ${theme.typography.h5.fontSize};
    `,
  }
}
