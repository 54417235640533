import { FieldConfigSource } from '@grafana/data'

export const overviewPanelConfig: FieldConfigSource = {
  defaults: {
    min: 0,
    custom: {
      spanNulls: true,
      showPoints: 'never',
      lineWidth: 2,
    },
  },
  overrides: [
    {
      matcher: {
        id: 'byRegexp',
        // Need to match both "VUs" (displayed in run overview) and "value(vus)" (displayed in dashboards)
        options: /(VUs|value\(vus\))/.toString(),
      },
      properties: [
        {
          id: 'custom.fillOpacity',
          value: 10,
        },
        {
          id: 'color',
          value: {
            mode: 'fixed',
            fixedColor: '#86868659',
          },
        },
      ],
    },
  ],
}
