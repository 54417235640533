import { head } from 'lodash'
import { ArrayDataFrame, FieldConfig, LoadingState, PanelData } from '@grafana/data'

import { MetricPayload, TestRun, TimeSeriesItem, TimeSeriesPayload } from 'types'
import { calculatePrecision } from 'utils/math'
import { createTimeRange, createTimeSeriesItem, separatorFormatter } from 'utils/formatters'

const initialPanelData: PanelData = {
  state: LoadingState.Loading,
  series: [],
  timeRange: createTimeRange('', ''),
}

const createTimeSeriesItems = (types: MetricPayload[]) => (payload: TimeSeriesPayload, index: number) => {
  const values = head(payload.value)?.values ?? []
  const label = types[index]?.label ?? ''

  return values.map(createTimeSeriesItem(label, 10))
}

const createTimeSeriesDataFrame = (items: TimeSeriesItem[][]) => (type: MetricPayload, index: number) => {
  const frame = new ArrayDataFrame(items[index] || [])

  if (frame?.fields?.length > 0) {
    const cfg: FieldConfig = { unit: type.unit }
    frame.fields[1]!.config = cfg
  }

  return frame
}

export const createMetricTypes = (dataId?: string): MetricPayload[] => [
  { method: 'value', metric: 'vus', unit: 'VUs', label: 'VUs', type: 'test_runs' },
  { method: 'rps', metric: 'http_reqs', unit: 'reqs/s', label: 'req rate', uid: dataId, type: 'http_urls' },
  { method: '0.95', metric: 'http_req_duration', unit: 'ms', label: 'resp time', uid: dataId, type: 'http_urls' },
  {
    method: 'nz_rps',
    metric: 'http_req_failed',
    unit: 'reqs/s',
    label: 'failed rate',
    uid: dataId,
    type: 'http_urls',
  },
]

export const createPanelData = (types: MetricPayload[], run: TestRun, payloads: TimeSeriesPayload[]): PanelData => {
  if (!payloads.length) {
    return initialPanelData
  }

  const items = payloads.map(createTimeSeriesItems(types))
  const frames = types.map(createTimeSeriesDataFrame(items))

  return {
    state: LoadingState.Done,
    series: frames,
    timeRange: createTimeRange(run.started, run.ended),
  }
}

export const getFormat = (key: string, row: any) => {
  const value: number = row.http_metric_summary?.duration?.[key] ?? 0
  return `${separatorFormatter(value, calculatePrecision(value))}ms`
}
