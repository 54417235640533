import React, { ReactNode } from 'react'
import { Icon, IconName } from '@grafana/ui'

import { StatusLevel } from '../../pages/Script/useFormStatus'
import { StyledMessage, Title, Text } from './FormStatusMessage.styles'

type Props = {
  level: StatusLevel
  title: string
  icon: IconName
  text?: ReactNode
}

export const FormStatusMessage = ({ level, title, text, icon }: Props) => {
  return (
    <StyledMessage $level={level}>
      {icon && <Icon name={icon} size="xl" />}
      {title && <Title>{title}</Title>}
      {text && <Text>{text}</Text>}
    </StyledMessage>
  )
}
