import { useFetch } from './useFetch'
import { useDatasource } from 'datasourceContext'
import { isTestDone } from 'pages/utils'
import { TestRun } from 'types'
import { BG_TASKS_POLL_INTERVAL } from 'constants/index'

const getRefreshInterval = (testRun?: TestRun) => {
  if (!testRun) {
    return BG_TASKS_POLL_INTERVAL
  }

  // Stop polling after test is done
  return isTestDone(testRun) ? 0 : BG_TASKS_POLL_INTERVAL
}

export const useRun = (id: number) => {
  const { ds } = useDatasource()

  return useFetch([id, 'testRun'], ds.fetchTestRun.bind(ds), {
    refreshInterval: (data) => getRefreshInterval(data),
  })
}
