import { useDatasource } from 'datasourceContext'
import { useFetch } from './useFetch'
import { useAppContext } from 'appContext'

export const useLoadZones = () => {
  const { ds } = useDatasource()
  const { orgId } = useAppContext()

  return useFetch([orgId, 'loadzones'], ds.fetchLoadZones.bind(ds))
}
