export const calculatePrecision = (value: number) => {
  if (value < 1) {
    return 2
  }

  if (value < 10) {
    return 1
  }

  return 0
}
