import React from 'react'
import { Spinner } from '@grafana/ui'

import { combineLoadZonesDistribution } from 'utils'
import { useLoadZones } from 'data/useLoadZones'
import { TestRunNode } from 'types'
import { LoadZoneLabel, LoadZoneTooltip } from 'components/LoadZones'

type Props = {
  nodes: TestRunNode[]
  distribution: [[string, number]]
  limit?: number
}

export const LoadZones = ({ nodes, distribution, limit = 4 }: Props) => {
  const { data: loadZones } = useLoadZones()

  if (!loadZones) {
    return <Spinner size={14} style={{ marginLeft: '5px' }} />
  }

  const zones = combineLoadZonesDistribution(distribution, nodes, loadZones)

  const visibleZones = zones.slice(0, limit)
  const restZones = zones.slice(limit)

  return (
    <>
      {visibleZones.map((zone) => (
        <LoadZoneTooltip key={zone.id} loadZones={[zone]}>
          <LoadZoneLabel
            country={zone.country}
            city={zone.city}
            loadPercent={zone.loadPercent}
            isPublic={zone.isPublic}
            showLabel
            showPercentage
            showPrivateIcon
          />
        </LoadZoneTooltip>
      ))}

      {restZones.length > 0 && (
        <LoadZoneTooltip loadZones={restZones}>
          <span>
            ...<strong>({restZones.length})</strong>
          </span>
        </LoadZoneTooltip>
      )}
    </>
  )
}
