import { dateTime, KeyValue, ThresholdsMode } from '@grafana/data'
import { FormattedRuns, TestRun } from 'types'
import { getTestRunColorString, padEmptyBars } from '../../../utils'
import { MAX_VALUE_EMPTY_BARS } from 'constants/index'

const MAX_BARS = 20

export const createFieldConfig = (maxValue: number) => ({
  defaults: {
    color: {
      mode: 'thresholds',
    },
    mappings: [],
    min: 0,
    max: maxValue,
    thresholds: {
      mode: ThresholdsMode.Absolute,
      steps: [
        {
          color: 'green',
          value: 0,
        },
      ],
    },
  },
})

export const getMaxLoadTime = (runs: TestRun[]) => {
  return Math.max(...runs.map((run) => run.load_time)) || MAX_VALUE_EMPTY_BARS
}

export const getRunsToPlot = (runs: TestRun[]) => {
  return runs.length < MAX_BARS ? padEmptyBars(runs, MAX_BARS - runs.length) : runs
}

export const getFieldOverrides = (formattedRuns: KeyValue<FormattedRuns>) => {
  return Object.entries(formattedRuns).map((run) => ({
    matcher: {
      id: 'byFrameRefID',
      options: run[0],
    },
    properties: [
      {
        id: 'color',
        value: {
          fixedColor: getTestRunColorString(run[1].status),
          mode: 'fixed',
        },
      },
    ],
  }))
}

export const createRange = (runs: TestRun[]) => {
  const rto = runs[0]?.created ?? ''
  const rfrom = runs[runs.length - 1]?.created ?? ''

  return {
    from: dateTime(rfrom),
    to: dateTime(rto),
    raw: { from: rfrom, to: rto },
  }
}
