import React, { ReactNode } from 'react'
import { Icon, IconName } from '@grafana/ui'
import styled from 'styled-components'

type Props = {
  icon: IconName
  children: ReactNode
}

const StyledMetaItem = styled.div`
  display: flex;
  align-items: center;
`

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`

export const MetaItem = ({ icon, children }: Props) => {
  return (
    <StyledMetaItem>
      <StyledIcon name={icon} />
      {children}
    </StyledMetaItem>
  )
}
