import { useActiveSubsription } from './useSubscriptions'
import { useAppContext } from 'appContext'
import { ORGANIZATION_ROLE_TYPES, PROJECT_ROLE_TYPES } from 'constants/roles'
import { Test } from 'types'
import { isTestRunnable } from 'utils/predicate'

export const useOrgRole = () => {
  const { orgId, account } = useAppContext()
  if (!orgId) {
    return
  }

  return account.organization_roles.find((role) => role.organization_id === orgId)
}

export const useProjectRole = () => {
  const { project, account } = useAppContext()

  if (!project || !account) {
    return
  }

  return account.project_roles.find((role) => role.project_id === project.id)
}

export const useIsUserOrgAdmin = () => {
  const orgRole = useOrgRole()

  if (!orgRole) {
    return false
  }

  return orgRole.role_id === ORGANIZATION_ROLE_TYPES.ADMIN
}

export const useHasCloudExecution = () => {
  const activeSubscription = useActiveSubsription()

  if (!activeSubscription) {
    return false
  }

  return activeSubscription.rules['load_test.k6_cloud_execution.allow'] ?? true
}

export const useHasUserWriteAccess = () => {
  const isUserOrgAdmin = useIsUserOrgAdmin()
  const projectRole = useProjectRole()

  // If current user is an org admin he already has the needed permissions
  if (isUserOrgAdmin) {
    return true
  }

  if (!projectRole) {
    return false
  }

  return projectRole.role_id === PROJECT_ROLE_TYPES.READ_WRITE || projectRole.role_id === PROJECT_ROLE_TYPES.ADMIN
}

export const useCanUserRunTests = () => {
  const activeSubscription = useActiveSubsription()
  const hasUserWriteAccess = useHasUserWriteAccess()
  const hasCloudExecution = useHasCloudExecution()

  return !!activeSubscription && hasUserWriteAccess && hasCloudExecution
}

export const useCanUserRunTest = (test?: Test) => {
  const canUserRunTests = useCanUserRunTests()

  // New test
  if (!test) {
    return canUserRunTests
  }

  return canUserRunTests && isTestRunnable(test)
}
