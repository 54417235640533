import { useState, useEffect } from 'react'
import useSWR, { Key, Fetcher, SWRConfiguration, SWRResponse } from 'swr'
import useSWRInfinite, { SWRInfiniteKeyLoader, SWRInfiniteFetcher, SWRInfiniteConfiguration } from 'swr/infinite'
import { useHistory } from 'react-router-dom'
import { History } from 'history'

import { stickyData } from 'utils/swrStickyMiddleware'
import { showAlert } from 'utils/showAlert'
import { sentryClient } from 'services/sentryClient'
import { ERROR_CODE } from 'constants/errors'

type Config = {
  useStickyData?: boolean
  alertOnError?: boolean
}

export const useFetch = <Data, SWRKey extends Key>(
  key: SWRKey,
  fetcher: Fetcher<Data, SWRKey>,
  config?: SWRConfiguration<Data> & Config
): SWRResponse<Data> => {
  const history = useHistory()
  const { useStickyData, onError, alertOnError = true, ...swrConfig } = config || {}
  const swrResponse = useSWR(key, fetcher, {
    ...swrConfig,
    use: useStickyData ? [stickyData] : [],
    onError: handleError(history, onError, alertOnError),
  })

  return swrResponse
}

export const useFetchPaginated = <Data, KeyLoader extends SWRInfiniteKeyLoader>(
  key: KeyLoader,
  fetcher: SWRInfiniteFetcher<Data, KeyLoader>,
  config?: SWRInfiniteConfiguration<Data> & Config
) => {
  const history = useHistory()
  const [isLoadingNext, setIsLoadingNext] = useState(false)

  const { useStickyData, onError, alertOnError = true, ...swrConfig } = config || {}
  const { error, setSize, data, ...swrResponse } = useSWRInfinite(key, fetcher, {
    ...swrConfig,
    onError: handleError(history, onError, alertOnError),
  })

  const loadNext = () => {
    setSize((size) => size + 1)
    setIsLoadingNext(true)
  }

  useEffect(() => {
    setIsLoadingNext(false)
  }, [data])

  return {
    ...swrResponse,
    data,
    loadNext,
    isLoading: !data || isLoadingNext,
    // First batch of data is being loaded
    isLoadingInitial: !data,
  }
}

const handleError =
  (history: History, customOnError?: (...args: any) => void, alertOnError?: boolean) => (error: any) => {
    sentryClient.captureException(error)
    customOnError && customOnError(error)
    console.log('error', error)

    if (error.status === 404 || (error.status === 400 && error.data?.error?.code === ERROR_CODE.NOT_AUTHENTICATED)) {
      showAlert('Unable to load resource', 'error')
      history.replace('/')
      return
    }

    alertOnError && showAlert('Failed to fetch resource, retrying...', 'error')
  }
