import styled from 'styled-components'

import { StatusLevel } from '../../pages/Script/useFormStatus'
import { getLevelColor } from './FormStatusMessage.utils'

export const StyledMessage = styled.div<{ $level: StatusLevel }>`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${({ $level, theme }) => getLevelColor($level, theme)};
  padding: 10px 0;

  .fa-spinner {
    width: 24px;
    height: 24px;
    font-size: 24px;
  }
`
export const Title = styled.div`
  font-weight: bold;
`
export const Text = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
`
