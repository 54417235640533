import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { Input, Button, HorizontalGroup, ConfirmModal } from '@grafana/ui'
import { format } from 'date-fns'

import { CodeEditor } from 'components/CodeEditor/CodeEditor'
import { Test } from 'types'
import { useFormStatus } from '../useFormStatus'
import { FormStatusMessage } from 'components/FormStatusMessage/FormStatusMessage'
import { RunButtonController } from 'components/RunButtonController'
import { isReadOnlyTest } from 'utils/predicate'
import { useUpdateTest } from 'data/useUpdateTest'
import { useCreateTest } from 'data/useCreateTest'
import { useRunTest } from 'data/useRunTest'
import { FormFields } from '../Script'
import { DEFAULT_SCRIPT } from 'constants/scripts'

type FormProps = {
  testId: string
  test?: Test
  projectId: number
  isNewTest: boolean
}

export const Form = ({ test, projectId, isNewTest, testId }: FormProps) => {
  const history = useHistory()

  const [isScriptError, setIsScriptError] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const [updateTest, { error: updateError }] = useUpdateTest()
  const [createTest, { error: createError }] = useCreateTest()
  const [runTest, { error: runError }] = useRunTest()

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    getValues,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<FormFields>({
    defaultValues: {
      name: test?.name,
      script: test?.script || test?.test_runs[0]?.script,
      project_id: projectId,
      id: test?.id,
    },
  })

  const saveButtonEnabled = isDirty && !isSubmitting
  const runButtonEnabled = !isSubmitting
  const isReadOnly = test && isReadOnlyTest(test)

  const formStatus = useFormStatus({
    errors,
    isSubmitting,
    isScriptError,
    isUnsaved: !isNewTest && isDirty,
    test: test,
  })

  useEffect(() => {
    if (!isNewTest) {
      return
    }

    const name = `Test ${format(new Date(), 'dd/MM/yyyy-HH:mm:ss')}`
    const script = DEFAULT_SCRIPT

    setValue('name', name, { shouldDirty: true })
    setValue('script', script, { shouldDirty: true })
  }, [isNewTest, setValue])

  useEffect(() => {
    const saveErrors = [createError, updateError]
    const saveError = saveErrors.find(Boolean)

    if (saveError) {
      // Even though it says non_field_errors, the only error I was able to trigger
      // was "name already exists" which belongs to name field.
      const message = saveError.data.error.field_errors.non_field_errors[0] || 'Validation failed'
      setError('name', { message })
      return
    }

    if (runError) {
      throw runError
    }
  }, [createError, updateError, runError, setError])

  const handleSave: SubmitHandler<FormFields> = async (data) => {
    const response = await saveTest(data)
    if (!response) {
      return
    }

    reset(response)
    isNewTest && history.replace(`/tests/${response.id}/script`)
  }

  const saveAndRun = async (data: FormFields) => {
    if (isDirty) {
      const response = await saveTest(data)
      return response && runTestAndRedirect(response.id)
    }
    return runTestAndRedirect(+testId)
  }

  const saveTest = async (data: FormFields) => {
    const saveFn = isNewTest ? createTest : updateTest
    return saveFn(data)
  }

  const runTestAndRedirect = async (testId: number) => {
    const run = await runTest(testId)
    run && history.push(`/runs/${run.id}`)
  }

  const handleSaveAndRun: SubmitHandler<FormFields> = async (data) => {
    if (isScriptError && !showConfirmationModal) {
      // show modal
      setShowConfirmationModal(true)
      return
    }
    setShowConfirmationModal(false)
    await saveAndRun(data)
  }

  const saveButtonLabel = isNewTest ? 'Create' : 'Save'
  const saveAndRunButtonLabel = isDirty ? `${saveButtonLabel} and Run` : 'Run test'
  const isScriptNotAvailable = isReadOnly && !getValues('script')
  const overlayMessage = isScriptNotAvailable ? 'No script available.' : null

  return (
    <div>
      <form onSubmit={handleSubmit(handleSave)}>
        <HorizontalGroup justify="space-between">
          <HorizontalGroup>
            <Input width={50} {...register('name', { required: { value: true, message: 'name is required' } })} />
          </HorizontalGroup>
          <HorizontalGroup>
            <Button type="submit" disabled={!saveButtonEnabled}>
              {saveButtonLabel}
            </Button>
            <RunButtonController test={test}>
              {({ isDisabled }) => (
                <Button
                  onClick={handleSubmit(handleSaveAndRun)}
                  variant={isScriptError ? 'destructive' : 'primary'}
                  disabled={!runButtonEnabled || isDisabled}
                >
                  {saveAndRunButtonLabel}
                </Button>
              )}
            </RunButtonController>
          </HorizontalGroup>
        </HorizontalGroup>
        <FormStatusMessage {...formStatus} />
        <Controller
          name="script"
          control={control}
          rules={{
            required: { value: true, message: 'script is required' },
          }}
          render={({ field }) => (
            <CodeEditor
              {...field}
              onValidation={setIsScriptError}
              readOnly={isReadOnly}
              overlayMessage={overlayMessage}
            />
          )}
        />

        <ConfirmModal
          title="Script errors found"
          isOpen={showConfirmationModal}
          body="Looks like there are errors in your script, do you want to force run the test anyway?"
          confirmText="Force run test"
          onDismiss={() => setShowConfirmationModal(false)}
          onConfirm={handleSubmit(handleSaveAndRun)}
        />
      </form>
    </div>
  )
}
