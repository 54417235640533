import React from 'react'
import { Link } from 'react-router-dom'
import { LinkButton, Tooltip, Button } from '@grafana/ui'

import { useHasUserWriteAccess } from 'data/usePermissions'

export const CreateTestButton = () => {
  const hasUserWriteAccess = useHasUserWriteAccess()

  if (hasUserWriteAccess) {
    return (
      <Link to="/tests/new/script">
        <LinkButton>New Test</LinkButton>
      </Link>
    )
  }

  return (
    <Tooltip content="You don't have permission to create new tests in this project">
      <Button disabled={true}>New Test</Button>
    </Tooltip>
  )
}
