import { head } from 'lodash'
import { ArrayDataFrame, FieldConfig, FieldConfigSource, LoadingState, PanelData, ThresholdsMode } from '@grafana/data'

import { MetricPayload, TimeSeries, TimeSeriesPayload } from 'types'
import { createTimeRangeOn, createTimeSeriesItem } from 'utils/formatters'

const initialPanelData: PanelData = {
  state: LoadingState.Loading,
  series: [],
  timeRange: createTimeRangeOn('timestamp', []),
}

const getTimeSeriesValue = (payload: TimeSeriesPayload[] = []) => {
  return head(head(payload)?.value)!
}

const createTimeSeriesDataFrame = ({ metric_name = '', values = [] }: TimeSeries) => {
  const series = values.map(createTimeSeriesItem(metric_name))
  const frame = new ArrayDataFrame(series)

  const hasFields = frame?.fields?.length > 0
  const hasDuration = metric_name.includes('duration')

  if (hasFields && hasDuration) {
    const cfg: FieldConfig = { unit: 'ms' }
    frame.fields[1]!.config = cfg
  }

  return frame
}

export const createMetricTypes = (dataId?: string): MetricPayload[] => [
  {
    type: 'thresholds',
    uid: dataId,
  },
]

export const createPanelData = (payloads: TimeSeriesPayload[] = []): PanelData => {
  if (!payloads.length) {
    return initialPanelData
  }

  const value = getTimeSeriesValue(payloads)
  const frame = createTimeSeriesDataFrame(value)

  return {
    state: LoadingState.Done,
    series: [frame],
    timeRange: createTimeRangeOn('timestamp', value?.values ?? []),
  }
}

export const createFieldConfig = (limit: number): FieldConfigSource => ({
  defaults: {
    min: 0,
    custom: {
      spanNulls: true,
      showPoints: 'never',
      lineWidth: 2,
      thresholdsStyle: {
        mode: 'line',
      },
    },
    thresholds: {
      mode: ThresholdsMode.Absolute,
      steps: [
        {
          value: 0,
          color: 'green',
        },
        {
          value: limit,
          color: 'red',
        },
      ],
    },
  },
  overrides: [],
})
