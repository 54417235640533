import { ArrayDataFrame, EventBusSrv, EventBusExtended, LoadingState, PanelData, FieldConfig } from '@grafana/data'

import { MetricPayload, TestRun, TimeSeriesPayload } from 'types'
import { createTimeRange, createTimeSeriesItem } from 'utils/formatters'

export const getMetricTypes = (): MetricPayload[] => [
  { method: 'value', metric: 'vus', unit: 'VUs', label: 'VUs', type: 'test_runs' },
  { method: 'rps', metric: 'http_reqs', unit: 'reqs/s', label: 'req rate', type: 'test_runs' },
  { method: '0.95', metric: 'http_req_duration', unit: 'ms', label: 'resp time', type: 'test_runs' },
  {
    method: 'nz_rps',
    metric: 'http_req_failed',
    unit: 'reqs/s',
    label: 'failed rate',
    type: 'test_runs',
  },
]

export const createPanelData = (
  series: TimeSeriesPayload[] = [],
  types: MetricPayload[] = [],
  testRun: TestRun
): PanelData | undefined => {
  if (!series.length) {
    return
  }

  const formattedSeries = (series || []).map((r, i) => {
    const values = r.value[0]?.values ?? []
    const label = types[i]?.label ?? ''

    return values.map(createTimeSeriesItem(label))
  })

  if (formattedSeries.every((series) => series.length === 0)) {
    return
  }

  const frames = formattedSeries.map((item, i) => {
    const frame = new ArrayDataFrame(item)

    if (frame.fields && frame.fields.length > 0) {
      const cfg: FieldConfig = { unit: types[i]?.unit }
      frame.fields[1]!.config = cfg
    }

    return frame
  })

  return {
    state: LoadingState.Error,
    series: frames,
    timeRange: createTimeRange(testRun.started, testRun.ended),
  }
}

export const appEvents: EventBusExtended = new EventBusSrv()
