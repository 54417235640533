import React, { MouseEvent } from 'react'
import { Badge, BadgeColor } from '@grafana/ui'

import { BadgeColorCodes, TestRun } from 'types'
import { getTestRunColorString, getTestStatusText, isTestAborted, isTestDone } from 'pages/utils'
import { getRelativeDateDifference } from 'utils/date'
import { unitFormatter, responseTimeFormatter, timeFormatter } from 'utils/formatters'
import { LastRunMetaProps, MetaTimeProps, MetaTitleProps } from './LastRunMeta.types'
import { BadgeLink, DetailsBody, TextNoBreak, TextOpaque, Time, TitleBody, TitleText } from './LastRunMeta.styles'

const MetaTime = ({ timestamp }: MetaTimeProps) => {
  if (!timestamp) {
    return null
  }

  const difference = getRelativeDateDifference(Date.now(), timestamp)

  return <Time>{difference} ago</Time>
}

const MetaTitle = ({ isTimeVisible = false, run }: MetaTitleProps) => {
  if (!run) {
    return (
      <TitleBody>
        <TitleText>No test runs</TitleText>
      </TitleBody>
    )
  }

  return (
    <TitleBody>
      <TitleText>LAST RUN</TitleText>
      {isTimeVisible && <MetaTime timestamp={run.created as string} />}
    </TitleBody>
  )
}

const MetaLink = (run: TestRun) => {
  const color = getTestRunColorString(run) as BadgeColor

  const handleLinkClick = (event: MouseEvent) => {
    event.stopPropagation()
  }

  return (
    <BadgeLink style={{ color: BadgeColorCodes[color] }} onClick={handleLinkClick} to={`/runs/${run.id}`}>
      <Badge color={color} text={getTestStatusText(run)} />
    </BadgeLink>
  )
}

const MetaDetails = ({ created, vus, duration = 0, load_time = 0 }: TestRun) => {
  if ((vus && duration > 0) || load_time) {
    return (
      <DetailsBody>
        {!!load_time && (
          <div>
            <TextOpaque>AVG RT</TextOpaque> <TextNoBreak>{responseTimeFormatter(load_time)} ms</TextNoBreak>
          </div>
        )}

        <div>
          <TextNoBreak>
            {unitFormatter(vus)} <span>VUs</span>
          </TextNoBreak>{' '}
          <TextOpaque>in</TextOpaque> <TextNoBreak>{timeFormatter(duration)}</TextNoBreak>
        </div>
      </DetailsBody>
    )
  }

  return null
}

export const LastRunMeta = ({ testRuns }: LastRunMetaProps) => {
  const lastRun = testRuns.slice(-1)[0]

  if (!lastRun) {
    return <MetaTitle />
  }

  const isTestAbortedOrActive = isTestAborted(lastRun.run_status) || !isTestDone(lastRun)

  return (
    <>
      <MetaTitle isTimeVisible={!isTestAbortedOrActive} run={lastRun} />
      <div>{isTestAbortedOrActive ? <MetaLink {...lastRun} /> : <MetaDetails {...lastRun} />}</div>
    </>
  )
}
