import { FormattedRuns, TestRun } from 'types'
import { KeyValue, LoadingState, PanelData } from '@grafana/data'
import { MAX_VALUE_EMPTY_BARS } from 'constants/index'
import { getTestRunColorString, loadFormattedRunsToDataframes, padEmptyBars } from 'pages/utils'
import { createTimeRangeOn } from 'utils/formatters'

const getMaxValue = (testRuns: TestRun[] = []) => {
  const loadTimes = testRuns.map((run) => run.load_time)
  const maxValue = Math.max(0, ...loadTimes)

  return maxValue || MAX_VALUE_EMPTY_BARS
}

export const getRunsToPlot = (testRuns: TestRun[] = []): TestRun[] => {
  const MAX_BARS = 20
  const createRunWithDefaults = (run: TestRun) => ({
    ...run,
    load_time: run.load_time || 0,
  })

  let runsToPlot = testRuns.map(createRunWithDefaults).reverse()

  if (runsToPlot.length < MAX_BARS) {
    runsToPlot = padEmptyBars(runsToPlot, MAX_BARS - runsToPlot.length)
  }

  return runsToPlot
}

export const createOverrides = (formattedRuns: KeyValue<FormattedRuns>) => {
  return Object.entries(formattedRuns).map((run) => {
    const statusColor = getTestRunColorString(run[1].status)

    return {
      matcher: {
        id: 'byFrameRefID',
        options: run[0],
      },
      properties: [
        {
          id: 'color',
          value: {
            fixedColor: statusColor,
            mode: 'fixed',
          },
        },
      ],
    }
  })
}

export const createFieldConfig = (testRuns: TestRun[] = []) => {
  return {
    defaults: {
      color: {
        mode: 'thresholds',
      },
      mappings: [],
      min: 0,
      max: getMaxValue(testRuns),
      thresholds: {
        mode: 'absolute',
        steps: [
          {
            color: 'green',
            value: null,
          },
          {
            color: 'red',
            value: 80,
          },
        ],
      },
    },
    overrides: [
      {
        matcher: {
          id: 'byName',
          options: 'failed',
        },
        properties: [
          {
            id: 'color',
            value: {
              mode: 'fixed',
              fixedColor: 'red',
            },
          },
        ],
      },
      {
        matcher: {
          id: 'byName',
          options: 'passed',
        },
        properties: [
          {
            id: 'color',
            value: {
              mode: 'palette-classic',
            },
          },
          {
            id: 'color',
            value: {
              mode: 'fixed',
              fixedColor: 'green',
            },
          },
        ],
      },
    ],
  }
}

export const createPanelData = (testRuns: TestRun[] = [], formattedRuns: KeyValue<FormattedRuns>): PanelData => {
  const range = createTimeRangeOn('created', testRuns)
  const frames = loadFormattedRunsToDataframes(formattedRuns)

  return {
    state: LoadingState.Done,
    series: frames,
    timeRange: range,
  }
}
