import { useTheme2 } from '@grafana/ui'
import { K6DataSource } from 'datasource/datasource'
import { createTableTheme } from 'pages/styles'
import React from 'react'
import DataTable from 'react-data-table-component'

import { TestRun } from 'types'
import { separatorFormatter } from 'utils/formatters'
import { useChecks } from 'data/useChecks'
import { Status } from '../Status'
import { ChecksChart } from './ChecksChart'
import { getSuccessRate } from './ChecksChart.utils'

interface Props {
  run: TestRun
  ds: K6DataSource
}

const columns = [
  {
    name: '',
    cell: (row: any) => <Status isValid={row.metric_summary.fail_count === 0} />,
    width: '4px',
    compact: true,
  },
  {
    name: 'Name',
    selector: (row: any) => row.name,
    minWidth: '200px',
  },
  {
    name: 'Success Rate',
    selector: (row: any) => getSuccessRate(row.metric_summary),
  },
  {
    name: 'Success Count',
    selector: (row: any) => separatorFormatter(row.metric_summary.success_count),
  },
  {
    name: 'Fail Count',
    selector: (row: any) => separatorFormatter(row.metric_summary.fail_count),
  },
]

export const ChecksTab = ({ ds, run }: Props) => {
  const { data: checks } = useChecks(run)
  const theme = useTheme2()

  createTableTheme()

  const themeName = theme.isDark ? 'grafana-dark' : ''

  if (!checks) {
    // TODO add spinner
    return null
  }

  return (
    <div>
      <DataTable
        columns={columns}
        data={checks.value}
        theme={themeName}
        highlightOnHover={true}
        pointerOnHover={true}
        expandableRowsHideExpander
        expandableRows
        expandOnRowClicked
        expandableRowsComponent={ChecksChart as any}
        expandableRowsComponentProps={{ ds, run }}
      />
    </div>
  )
}
