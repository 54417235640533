import { useTheme2 } from '@grafana/ui'
import { K6DataSource } from 'datasource/datasource'
import { createTableTheme } from 'pages/styles'
import React from 'react'
import DataTable from 'react-data-table-component'

import { TestRun } from 'types'
import { separatorFormatter } from 'utils/formatters'
import { useHttpStats } from 'data/useHttpStats'
import { Status } from '../Status'
import { HttpChart } from './HttpChart'
import { getFormat } from './HttpChart.utils'

interface Props {
  run: TestRun
  ds: K6DataSource
}

const columns = [
  {
    name: '',
    cell: (row: any) => <Status isValid={row.expected_response}></Status>,
    width: '4px',
    compact: true,
  },
  {
    name: 'URL',
    selector: (row: any) => row.name,
    minWidth: '250px',
  },
  {
    name: 'Method',
    selector: (row: any) => row.method,
  },
  {
    name: 'Status',
    selector: (row: any) => row.status,
  },
  {
    name: 'Count',
    selector: (row: any) => separatorFormatter(row.http_metric_summary.requests_count),
  },
  {
    name: 'Min',
    selector: (row: any) => getFormat('min', row),
  },
  {
    name: 'Stddev',
    selector: (row: any) => getFormat('stdev', row),
  },
  {
    name: 'P95',
    selector: (row: any) => getFormat('p95', row),
  },
  {
    name: 'P99',
    selector: (row: any) => getFormat('p99', row),
  },
  {
    name: 'Max',
    selector: (row: any) => getFormat('max', row),
  },
]

export const HttpTab = ({ run, ds }: Props) => {
  const { data: http } = useHttpStats(run)
  const theme = useTheme2()

  const themeName = theme.isDark ? 'grafana-dark' : ''
  createTableTheme()

  if (!http) {
    // TODO add spinner
    return null
  }

  return (
    <div>
      <DataTable
        columns={columns}
        data={http.value}
        theme={themeName}
        highlightOnHover
        pointerOnHover
        expandableRowsHideExpander
        expandableRows
        expandOnRowClicked
        expandableRowsComponent={HttpChart as any}
        expandableRowsComponentProps={{ ds, run }}
      />
    </div>
  )
}
