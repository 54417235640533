import { useDatasource } from 'datasourceContext'
import { MetricPayload, TestRun } from 'types'
import { useFetch } from './useFetch'

export const useTimeSeries = (testRun: TestRun, types: MetricPayload[]) => {
  const { ds } = useDatasource()

  const fetchSeries = (runId: number) => {
    const calls = types.map((t) =>
      ds.fetchTimeSeries({
        ...t,
        queryType: t.type,
        testRunId: runId,
      })
    )

    return Promise.all(calls)
  }

  return useFetch([testRun, 'series', types], (testRun) => fetchSeries(testRun.id), {
    useStickyData: true,
  })
}
