import React, { ReactNode } from 'react'
import styled from 'styled-components'

const StyledOverlay = styled.div`
  position: absolute;
  inset: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.size.lg};
`

export const Overlay = ({ children }: { children: ReactNode }) => <StyledOverlay>{children}</StyledOverlay>
