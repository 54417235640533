import React from 'react'
import { Card, Spinner } from '@grafana/ui'
import { get } from 'lodash-es'

import { isNumeric } from 'utils/predicate'
import { separatorFormatter, responseTimeFormatter } from 'utils/formatters'
import { useSummary } from 'data/useSummary'
import { MetaProps, RunSummaryProps } from './RunSummary.types'
import { Grid } from './RunSummary.styles'

const metrics = [
  {
    label: 'REQUESTS MADE',
    unit: 'reqs',
    key: 'http_metric_summary.requests_count',
    formatter: (val: number) => separatorFormatter(val),
  },
  {
    label: 'HTTP FAILURES',
    unit: 'reqs',
    key: 'http_metric_summary.failures_count',
    formatter: (val: number) => separatorFormatter(val),
  },
  {
    label: 'PEAK RPS',
    unit: 'reqs',
    key: 'http_metric_summary.rps_max',
    formatter: (val: number) => (isNumeric(val) ? separatorFormatter(val, 2, ' ') : '-'),
  },
  {
    label: 'P95 RESPONSE TIME',
    unit: 'ms',
    key: 'http_metric_summary.duration.p95',
    formatter: (val: number) => responseTimeFormatter(val),
  },
]

const Meta = ({ summary, metric }: MetaProps) => {
  if (!summary) {
    return (
      <span>
        <Spinner />
      </span>
    )
  }

  const value = metric.formatter(get(summary, metric.key))

  return (
    <span>
      {value} {metric.unit}
    </span>
  )
}

export const RunSummary = ({ testRun }: RunSummaryProps) => {
  const { data: summary } = useSummary(testRun)

  return (
    <Grid>
      {metrics.map((metric) => (
        <Card key={metric.label}>
          <Card.Heading>{metric.label}</Card.Heading>
          <Card.Meta>
            <div>
              <Meta summary={summary} metric={metric} />
            </div>
          </Card.Meta>
        </Card>
      ))}
    </Grid>
  )
}
