import { useEffect, useState } from 'react'
import { union } from 'lodash-es'

import { useFetchPaginated } from './useFetch'
import { useDatasource } from 'datasourceContext'
import { Test, Tests, TestRun, TestSortOptions } from 'types'
import { useActiveRuns } from './useActiveRuns'
import { mergeArraysByKey } from 'utils/mergeArrayByKey'

type GetActiveTests = (
  testsFlat: Test[],
  activeRuns: TestRun[],
  setNewTestIds: (fn: (ids: number[]) => number[]) => void
) => Test[]

const getActiveTests: GetActiveTests = (testsFlat, activeRuns, setNewTestIds) => {
  return activeRuns.flatMap((testRun) => {
    const test = testsFlat.find((t) => t.id === testRun.test_id)

    if (!test) {
      // New test started, fetch it
      setNewTestIds((ids) => union(ids, [testRun.test_id]))
      return []
    }

    const runs = mergeArraysByKey(test.test_runs, [testRun], 'id')
    return [{ ...test, test_runs: runs }]
  })
}

export const useTests = (projectId: number, search?: string, order?: TestSortOptions) => {
  const { ds } = useDatasource()
  const [testsFlat, setTestsFlat] = useState<Test[]>([])
  const [newTestIds, setNewTestIds] = useState<number[]>([])

  const getTestsKey = (pageIndex: number, previousPageData: Tests) => {
    if (previousPageData && previousPageData.meta.count < pageIndex + 1) {
      return null
    }
    return {
      search,
      project: projectId,
      page: pageIndex + 1,
      orderBy: order,
    }
  }

  const { data, size, loadNext, isLoading } = useFetchPaginated(getTestsKey, ds.fetchTests.bind(ds))

  const { data: activeRuns } = useActiveRuns()

  const flattenTests = () => {
    if (!data) {
      return
    }

    setTestsFlat(data.flatMap((page) => page['k6-tests']))
  }

  const setActiveTests = () => {
    if (!activeRuns || !data) {
      return
    }

    setTestsFlat((tests) => {
      const activeTests = getActiveTests(tests, activeRuns, setNewTestIds)
      return mergeArraysByKey(tests, activeTests, 'id')
    })
  }

  const fetchNewlyStartedTests = () => {
    if (newTestIds.length === 0) {
      return
    }

    newTestIds.forEach((id) => {
      ds.fetchTest(id).then((test) => {
        setTestsFlat((tests) => [test, ...tests])
      })
    })

    setNewTestIds([])
  }

  useEffect(flattenTests, [data])
  useEffect(setActiveTests, [activeRuns, setNewTestIds, data])
  useEffect(fetchNewlyStartedTests, [ds, newTestIds])

  const pageCount = data?.[0]?.meta?.count || 1

  return {
    isLoading,
    tests: testsFlat,
    loadNext: () => size < pageCount && loadNext(),
  }
}
