import React, { SyntheticEvent } from 'react'
import { last } from 'lodash-es'
import { useHistory } from 'react-router-dom'
import { ToolbarButton } from '@grafana/ui'

import { TestRun } from 'types'
import { useRunTest } from 'data/useRunTest'
import { LastRunMeta } from '../LastRunMeta'
import { StatusIndicator } from '../StatusIndicator'
import { TrendingChart } from '../TrendingChart'
import { TestProps } from './TestCard.types'
import { getSortedTestRuns } from './TestCard.utils'
import { Card, Header } from './TestCard.styles'
import { useHasUserWriteAccess } from 'data/usePermissions'
import { RunButtonController } from 'components/RunButtonController'

export const TestCard = ({ test }: TestProps) => {
  const history = useHistory()
  const hasUserWriteAccess = useHasUserWriteAccess()

  const handleRunTestSuccess = ({ data }: { data: TestRun }) => {
    history.push(`/runs/${data.id}`)
  }

  const [runTest, { status }] = useRunTest({ onSuccess: handleRunTestSuccess })

  const sortedRuns = getSortedTestRuns(test.test_runs)
  const buttonIcon = status === 'running' ? 'fa fa-spinner' : 'play'

  const handleRunTest = (e: SyntheticEvent) => {
    e.stopPropagation() // prevent redirect before test is started
    runTest(test.id)
  }

  const handleOpenTest = () => {
    history.push(`/tests/${test.id}`)
  }

  const handleEditTest = (e: React.MouseEvent) => {
    e.stopPropagation()
    history.push(`/tests/${test.id}/script`)
  }

  return (
    <Card onClick={handleOpenTest}>
      <Header>
        <StatusIndicator testRun={last(sortedRuns)} />
        <h5>{test.name}</h5>
        {hasUserWriteAccess && (
          <>
            <ToolbarButton icon="pen" tooltip="Edit test" onClick={handleEditTest} />
            <RunButtonController test={test}>
              {({ isDisabled }) => (
                <ToolbarButton
                  disabled={status === 'running' || isDisabled}
                  icon={buttonIcon}
                  tooltip="Start test"
                  onClick={handleRunTest}
                />
              )}
            </RunButtonController>
          </>
        )}
      </Header>

      <section>
        <LastRunMeta testRuns={sortedRuns} />
      </section>

      <TrendingChart sortedRuns={sortedRuns} />
    </Card>
  )
}
