import React, { ReactNode } from 'react'
import { isEmpty } from 'lodash'
import { FieldError } from 'react-hook-form'
import { IconName, Badge } from '@grafana/ui'

import { Test } from 'types'
import { isCLITest, isBuilderTest, isSecureTest } from 'utils/predicate'
import { ExternalLink } from 'components/ExternalLink'
import { useK6Url } from 'hooks/useK6Url'

export type StatusLevel = 'loading' | 'saving' | 'success' | 'error' | 'unsaved' | 'warning'

type MessageMap = {
  [key in StatusLevel]: {
    level: StatusLevel
    title: string
    icon: IconName
    text?: ReactNode
  }
}
const messageMap: MessageMap = {
  loading: {
    level: 'loading',
    title: 'LOADING...',
    icon: 'fa fa-spinner',
  },
  saving: {
    level: 'saving',
    icon: 'fa fa-spinner',
    title: 'SAVING...',
  },
  success: {
    level: 'success',
    icon: 'check-circle',
    title: 'PERFECT!',
  },
  error: {
    level: 'error',
    icon: 'circle',
    title: 'ERROR',
  },
  unsaved: {
    level: 'unsaved',
    icon: 'check-circle',
    title: 'UNSAVED',
  },
  warning: {
    level: 'warning',
    icon: 'check-circle',
    title: 'WARNING',
  },
}

type UseFormProps = {
  isUnsaved: boolean
  isScriptError: boolean
  isSubmitting: boolean
  errors: {
    [key: string]: FieldError | undefined
  }
  test?: Test
}

export const useFormStatus = ({ isUnsaved, errors, isScriptError, isSubmitting, test }: UseFormProps) => {
  const k6Url = useK6Url()

  if (isSubmitting) {
    return messageMap.loading
  }

  if (test && isBuilderTest(test)) {
    return {
      ...messageMap.warning,
      text: (
        <>
          This test was built in Test Builder, to edit visit{' '}
          <ExternalLink url={`${k6Url}tests/${test.id}/config`}>k6 cloud</ExternalLink>
        </>
      ),
      icon: 'lock' as IconName,
      title: 'READ ONLY',
    }
  }

  if (!isEmpty(errors)) {
    return {
      ...messageMap.error,
      text: Object.values(errors)[0]?.message,
    }
  }

  if (isScriptError) {
    return {
      ...messageMap.error,
      text: 'There are validation errors, please fix them in order to run the test',
    }
  }

  if (isUnsaved) {
    return messageMap.unsaved
  }

  if (test && isCLITest(test)) {
    return {
      ...messageMap.warning,
      text: (
        <>
          This script was executed from CLI. To modify the script, you need to edit it locally and run{' '}
          <Badge text="k6 cloud" color="purple" /> or <Badge text="k6 run -o cloud" color="purple" /> command again
        </>
      ),
      icon: 'lock' as IconName,
      title: 'READ ONLY',
    }
  }

  if (test && isSecureTest(test)) {
    return {
      ...messageMap.warning,
      text: 'Cannot modify CLI k6 cloud (secure)',
      icon: 'lock' as IconName,
      title: 'READ ONLY',
    }
  }

  return {
    ...messageMap.success,
    text: 'Script looks fine and is ready to run',
  }
}
