import React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { PanelRenderer } from '@grafana/runtime'
import { LegendDisplayMode, PanelChrome } from '@grafana/ui'

import { QueryType } from 'types'
import { useTimeSeries } from 'data/useTimeSeries'
import { ClipBoard } from 'components/Clipboard'
import { ChecksChartProps } from './ChecksTab.types'
import { createMetricTypes, createPanelData, fieldConfig } from './ChecksChart.utils'

const noop = () => {}

const panelConfig = {
  defaults: fieldConfig,
  overrides: [],
}

const panelOptions = {
  legend: {
    displayMode: LegendDisplayMode.List,
    placement: 'bottom',
    calcs: [],
  },
  graph: {},
  tooltipOptions: {
    mode: 'single',
  },
}

export const ChecksChart = ({ run, ds, data }: ChecksChartProps) => {
  const { data: series = [] } = useTimeSeries(run, createMetricTypes(data.id))
  const panelData = createPanelData(series, run)

  return (
    <div>
      <ClipBoard name={ds.name} queryType={QueryType.CHECKS} testRun={run} itemId={data.id} fieldConfig={panelConfig} />
      <AutoSizer disableHeight>
        {(size) => {
          return (
            <PanelChrome
              width={size.width}
              height={300}
              leftItems={[<PanelChrome.LoadingIndicator loading={false} onCancel={noop} key="loading-indicator" />]}
            >
              {(innerWidth, innerHeight) => (
                <PanelRenderer
                  title=""
                  pluginId="timeseries"
                  onOptionsChange={noop}
                  width={innerWidth}
                  height={innerHeight}
                  data={panelData}
                  options={panelOptions}
                  fieldConfig={panelConfig}
                />
              )}
            </PanelChrome>
          )
        }}
      </AutoSizer>
    </div>
  )
}
