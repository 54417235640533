import React, { ReactNode } from 'react'
import { CodeEditor as GrafanaCodeEditor } from '@grafana/ui'
import type * as monacoType from 'monaco-editor/esm/vs/editor/editor.api'

import k6Types from './types'
import { Overlay } from 'components/Overlay'

const addk6Types = (monaco: typeof monacoType) => {
  Object.entries(k6Types).map(([name, type]) => {
    // Import types as modules for code completions
    monaco.languages.typescript.javascriptDefaults.addExtraLib(`declare module '${name}' { ${type} }`)
  })

  // Remove TS errors for remote libs imports
  monaco.languages.typescript.javascriptDefaults.addExtraLib("declare module 'https://*'")
}

type CodeEditorProps = {
  value: string
  onChange?: (value: string) => void
  onValidation?: (hasError: boolean) => void
  readOnly?: boolean
  overlayMessage: ReactNode
}

export const CodeEditor = ({ value, onChange, onValidation, readOnly, overlayMessage }: CodeEditorProps) => {
  const handleChange = (editor: monacoType.editor.IStandaloneCodeEditor) => () => {
    const value = editor.getValue()
    onChange && onChange(value)
  }

  const handleValidation = (monaco: typeof monacoType) => {
    if (!onValidation) {
      return
    }

    const markers = monaco.editor.getModelMarkers({})
    const hasError = markers.some((marker) => marker.severity > 1)
    onValidation(hasError)
  }

  const handleBeforeEditorMount = (monaco: typeof monacoType) => {
    addk6Types(monaco)

    const compilerOptions = monaco.languages.typescript.javascriptDefaults.getCompilerOptions()
    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      ...compilerOptions,
      checkJs: true, // show errors for JS files, by default it check only TS
    })

    // Needed to make `checkJs` work and highlight errors
    monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSyntaxValidation: false,
      noSemanticValidation: false,
      noSuggestionDiagnostics: false,
    })
  }

  const handleEditorDidMount = (editor: monacoType.editor.IStandaloneCodeEditor, monaco: typeof monacoType) => {
    editor.onDidChangeModelContent(handleChange(editor))

    monaco.editor.onDidChangeMarkers(() => {
      handleValidation(monaco)
    })
  }

  return (
    <div style={{ position: 'relative' }}>
      {overlayMessage && <Overlay>{overlayMessage}</Overlay>}
      <GrafanaCodeEditor
        value={value}
        language="javascript"
        height="600px"
        showLineNumbers={true}
        showMiniMap={false}
        monacoOptions={{
          scrollBeyondLastLine: false,
        }}
        onBeforeEditorMount={handleBeforeEditorMount}
        onEditorDidMount={handleEditorDidMount}
        readOnly={readOnly}
      />
    </div>
  )
}
