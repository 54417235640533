import React, { useMemo } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { PanelRenderer } from '@grafana/runtime'
import { LegendDisplayMode, PanelChrome, PanelContext, PanelContextProvider } from '@grafana/ui'
import { QueryType, TestRun } from 'types'
import { useTimeSeries } from 'data/useTimeSeries'
import { ClipBoard } from 'components/Clipboard'
import { useDatasource } from 'datasourceContext'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { isTestActive } from 'pages/utils'
import { overviewPanelConfig } from 'utils/panelConfig'
import { OverviewChartProps } from './OverviewChart.types'
import { appEvents, getMetricTypes, createPanelData } from './OverviewChart.utils'
import { CenteredMessage } from './OverviewChart.styles'

const panelContext: PanelContext = {
  eventBus: appEvents,
  canAddAnnotations: () => false,
}

const panelOptions = {
  legend: {
    displayMode: LegendDisplayMode.List,
    placement: 'bottom',
    calcs: [],
  },
  graph: {},
  tooltipOptions: {
    mode: 'multi',
  },
  tooltip: {
    mode: 'multi',
  },
}

const Panel = ({ testRun, width, height }: { testRun: TestRun; width: number; height: number }) => {
  const types = useMemo(getMetricTypes, [testRun.id])
  const { data: series } = useTimeSeries(testRun, types)

  const panelData = createPanelData(series, types, testRun)
  const isActive = isTestActive(testRun)
  const isLoading = !series || (!panelData && isActive)
  const noData = !panelData && !isActive

  if (isLoading) {
    return <CenteredSpinner />
  }

  if (noData) {
    return <CenteredMessage>No data recorded</CenteredMessage>
  }

  return (
    <PanelContextProvider value={panelContext}>
      <PanelRenderer
        title=""
        pluginId="timeseries"
        onOptionsChange={() => {}}
        width={width}
        height={height}
        data={panelData!}
        options={panelOptions}
        fieldConfig={overviewPanelConfig}
      />
    </PanelContextProvider>
  )
}

export const OverviewChart = ({ testRun }: OverviewChartProps) => {
  const { ds } = useDatasource()

  return (
    <div>
      <ClipBoard name={ds.name} queryType={QueryType.METRIC} testRun={testRun} fieldConfig={overviewPanelConfig} />
      <AutoSizer disableHeight>
        {(size) => (
          <PanelChrome width={size.width} height={300} leftItems={[<div key="spacer" style={{ height: 32 }} />]}>
            {(innerWidth, innerHeight) => <Panel testRun={testRun} width={innerWidth} height={innerHeight} />}
          </PanelChrome>
        )}
      </AutoSizer>
    </div>
  )
}
