import React, { useEffect } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import { Button, HorizontalGroup, Icon, LinkButton } from '@grafana/ui'

import { TestRun } from 'types'
import { isTestActive } from '../utils'
import { ButtonTypes, GoToK6Button } from 'components/GoToK6Button'
import { useAppContext } from 'appContext'
import { useBreadcrumbs } from 'breadcrumbsContext'
import { useRuns } from 'data/useRuns'
import { useTest } from 'data/useTest'
import { useRunTest } from 'data/useRunTest'
import { createBreadcrumbs } from './RunsPage.utils'
import { RunsTable, TrendingChart } from './components'
import { useHasUserWriteAccess } from 'data/usePermissions'
import { RunButtonController } from 'components/RunButtonController'

export const RunsPage = () => {
  const history = useHistory()
  const { testId } = useParams<{ testId: string }>()
  const { project } = useAppContext()
  const { setBreadcrumbs } = useBreadcrumbs()
  const { runs, isLoading, isLoadingInitial, loadNext } = useRuns(+testId)
  const { data: test } = useTest(+testId)
  const hasUserWriteAccess = useHasUserWriteAccess()

  const handleRunTestSuccess = ({ data }: { data: TestRun }) => {
    history.push(`/runs/${data.id}`)
  }

  const [runTest, { status: runStatus }] = useRunTest({
    onSuccess: handleRunTestSuccess,
  })

  const isRunActive = runs.some(isTestActive)
  const runButtonIcon = runStatus === 'running' ? 'fa fa-spinner' : undefined

  useEffect(() => {
    if (!project || !test) {
      return
    }

    setBreadcrumbs(createBreadcrumbs(test))
  }, [project, test, setBreadcrumbs])

  return (
    <>
      <HorizontalGroup justify={'space-between'}>
        <div>
          {isRunActive && (
            <h3>
              <Icon name="fa fa-spinner" /> Tests are running
            </h3>
          )}
        </div>
        <HorizontalGroup justify={'flex-end'}>
          {hasUserWriteAccess && (
            <HorizontalGroup>
              <RunButtonController test={test}>
                {({ isDisabled }) => (
                  <Button
                    key="settings"
                    disabled={runStatus === 'running' || isDisabled}
                    icon={runButtonIcon}
                    onClick={() => runTest(+testId)}
                  >
                    Run Test
                  </Button>
                )}
              </RunButtonController>
              <Link to={`/tests/${testId}/script`}>
                <LinkButton variant="secondary">Configure</LinkButton>
              </Link>
            </HorizontalGroup>
          )}
          <GoToK6Button type={ButtonTypes.Test} id={+testId}></GoToK6Button>
        </HorizontalGroup>
      </HorizontalGroup>
      <TrendingChart runs={runs} />
      <RunsTable runs={runs} isLoading={isLoading} loadNext={loadNext} isLoadingInitial={isLoadingInitial} />
    </>
  )
}
