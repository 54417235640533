import React from 'react'
import { FieldConfigSource } from '@grafana/data'
import { PanelRenderer } from '@grafana/runtime'
import { PanelChrome } from '@grafana/ui'
import AutoSizer from 'react-virtualized-auto-sizer'

import { formatRunsForDataFrame } from 'pages/utils'
import { TrendingChartProps } from './TrendingChart.types'
import { createFieldConfig, createOverrides, createPanelData, getRunsToPlot } from './TrendingChart.utils'

const panelOptions = {
  displayMode: 'gradient',
  orientation: 'auto',
  reduceOptions: {
    calcs: ['lastNotNull'],
    fields: '',
    limit: 1000,
    values: true,
  },
  showUnfilled: true,
  text: {
    titleSize: 0,
    valueSize: 0,
  },
}

export const TrendingChart = ({ sortedRuns }: TrendingChartProps) => {
  const fieldConfig = createFieldConfig(sortedRuns)
  const runsToPlot = getRunsToPlot(sortedRuns)
  const formattedRuns = formatRunsForDataFrame(runsToPlot)
  const panelData = createPanelData(runsToPlot, formattedRuns)
  const overrides = createOverrides(formattedRuns)

  return (
    <AutoSizer disableHeight>
      {(size) => (
        <PanelChrome width={size.width} height={70}>
          {(innerWidth, innerHeight) => (
            <PanelRenderer
              title="Trending p95 response time"
              pluginId="bargauge"
              width={innerWidth}
              height={innerHeight}
              data={panelData}
              options={panelOptions}
              fieldConfig={{ ...fieldConfig, overrides } as FieldConfigSource}
            />
          )}
        </PanelChrome>
      )}
    </AutoSizer>
  )
}
