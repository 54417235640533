import React from 'react'
import styled from 'styled-components'

type StatusProps = { isValid: boolean }

const Bar = styled.div<StatusProps>`
  background-color: ${({ isValid }) => (isValid ? '#28a745' : '#dc3545')};
  height: 44px;
`

export const Status = ({ isValid }: StatusProps) => {
  return <Bar isValid={isValid}>&nbsp;</Bar>
}
