import { groupBy, head, round } from 'lodash'
import { LoadingState, PanelData, toDataFrame } from '@grafana/data'

import { CheckMetricSummary, MetricPayload, TimeSeries, TimeSeriesPayload, TestRun } from 'types'
import { isNumeric } from 'utils/predicate'
import { createDataFrameFields, createTimeRangeOn, createTimeRange } from 'utils/formatters'

const initialPanelData: PanelData = {
  state: LoadingState.Loading,
  series: [],
  timeRange: createTimeRangeOn('timestamp', []),
}

const getTimeSeriesValue = (payload: TimeSeriesPayload[] = []) => {
  return head(head(payload)?.value)!
}

const createTimeSeriesDataFrame = ({ method = '', values = [] }: TimeSeries) => {
  const config = {
    ...fieldConfig,
    displayName: method,
  }

  return toDataFrame({
    name: method,
    fields: createDataFrameFields(values, config),
  })
}

export const fieldConfig = {
  custom: {
    drawStyle: 'bars',
    fillOpacity: 100,
    lineWidth: 4,
    spanNulls: true,
    barAlignment: 1,
  },
}

export const createMetricTypes = (dataId: string): MetricPayload[] => [
  {
    type: 'checks',
    method: 'passes',
    uid: dataId,
  },
  {
    type: 'checks',
    method: 'failures',
    uid: dataId,
  },
]

export const createPanelData = (payloads: TimeSeriesPayload[] = [], testRun: TestRun): PanelData => {
  if (!payloads.length) {
    return initialPanelData
  }

  const { passes, failures } = groupBy(payloads, ({ value = [] }) => head(value)?.method)
  const passValue = getTimeSeriesValue(passes)
  const passFrame = createTimeSeriesDataFrame(passValue)
  const failValue = getTimeSeriesValue(failures)
  const failFrame = createTimeSeriesDataFrame(failValue)

  return {
    state: LoadingState.Done,
    series: [passFrame, failFrame],
    timeRange: createTimeRange(testRun.started, testRun.ended),
  }
}

export const getSuccessRate = (summary: CheckMetricSummary) => {
  return isNumeric(summary.success_rate) ? `${round(summary.success_rate * 100, 2)}%` : '-'
}
