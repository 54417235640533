import React from 'react'
import { Icon, Tooltip, useTheme2 } from '@grafana/ui'
import DataTable from 'react-data-table-component'
import { useHistory } from 'react-router-dom'

import { Account, TestRun } from 'types'
import { useAccount } from 'data/useAccount'
import { getDateInTimezone } from 'utils/date'
import { responseTimeFormatter } from 'utils/formatters'
import { getTestStatusText } from 'pages/utils'
import { createTableTheme } from 'pages/styles'
import { InfiniteScroll } from 'components/InfiniteScroll'
import { LoadZones } from 'components/LoadZones'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { RunsTableProps, StatusCellProps } from './RunsTable.types'
import { getConfig } from './RunsTable.utils'
import { StatusCellBody, StatusCellText } from './RunsTable.styles'

export const StatusCell = ({ testRun }: StatusCellProps) => {
  const statusText = getTestStatusText(testRun)

  if (testRun.is_baseline) {
    return (
      <StatusCellBody>
        <Tooltip content={'Baseline'} placement={'bottom-start'}>
          <Icon name="star" />
        </Tooltip>
        <StatusCellText $marginLeft="10px">{statusText}</StatusCellText>
      </StatusCellBody>
    )
  }

  if (testRun.delete_status === 1) {
    return (
      <StatusCellBody>
        <Tooltip content={'Saved test result'} placement={'bottom'}>
          <Icon name="lock" />
        </Tooltip>
        <StatusCellText $marginLeft="10px">{statusText}</StatusCellText>
      </StatusCellBody>
    )
  }

  return (
    <StatusCellBody>
      <StatusCellText>{statusText}</StatusCellText>
    </StatusCellBody>
  )
}

const getColumns = ({ user }: Partial<Account> = {}) => [
  {
    name: 'Status',
    cell: (row: TestRun) => <StatusCell testRun={row} />,
  },
  {
    name: 'Config',
    selector: (row: TestRun) => getConfig(row),
  },
  {
    name: 'Load distribution',
    cell: (row: TestRun) => <LoadZones nodes={row.nodes} distribution={row.distribution} />,
  },
  {
    name: 'Started',
    selector: (row: TestRun) => (row.started ? getDateInTimezone(row.started, 'MMM dd, HH:mm', user?.time_zone) : ''),
  },
  {
    name: 'Response Time',
    selector: (row: TestRun) => `${row.load_time ? responseTimeFormatter(row.load_time) : ' - '} ms`,
  },
  {
    name: 'Note',
    selector: (row: TestRun) => row.note,
  },
]

export const RunsTable = ({ runs, isLoading, isLoadingInitial, loadNext }: RunsTableProps) => {
  const { data: account } = useAccount()
  const history = useHistory()
  const theme = useTheme2()
  createTableTheme()
  const themeName = theme.isDark ? 'grafana-dark' : ''

  const handleRowClick = (row: TestRun) => {
    history.push(`/runs/${row.id}`)
  }

  if (isLoadingInitial) {
    return <CenteredSpinner $height="100px" />
  }

  return (
    <InfiniteScroll isLoading={isLoading} loadNext={loadNext}>
      <DataTable
        columns={getColumns(account)}
        data={runs}
        theme={themeName}
        onRowClicked={handleRowClick}
        highlightOnHover={true}
        pointerOnHover={true}
      />
    </InfiniteScroll>
  )
}
