import styled from 'styled-components'

export const StatusCellBody = styled.span`
  pointer-events: none;
  display: inline-flex;
  align-items: center;
`

export const StatusCellText = styled.span<{ $marginLeft?: string }>`
  margin-left: ${({ $marginLeft = '10px' }) => $marginLeft};
`
