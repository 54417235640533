import { DefaultTheme } from 'styled-components'

import { StatusLevel } from '../../pages/Script/useFormStatus'

export const getLevelColor = (level: StatusLevel, theme: DefaultTheme) => {
  switch (level) {
    case 'success':
      return theme.colors.success.text
    case 'error':
      return theme.colors.error.text
    case 'unsaved':
    case 'warning':
      return theme.colors.warning.text
    default:
      return theme.colors.text.primary
  }
}
