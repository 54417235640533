export const ERROR_CODE = {
  // Custom Frontend errors
  PRODUCT_TOKEN_NOT_FOUND: -1000,
  AXIOS_REQUEST_CANCELED: -2000,
  NETWORK_ERROR: -2,
  UNKNOWN_ERR: -1,

  // Error codes copied from API repo api3/errors.py
  //  General errors 1-999
  UNKNOWN: 0,
  GENERAL: 1,
  VALIDATION: 2,
  NOT_FOUND: 3,
  NOT_ALLOWED: 4,
  NOT_AUTHENTICATED: 5,
  AUTHENTICATION_FAILED: 6,
  METHOD_NOT_ALLOWED: 7,
  ALREADY_MEMBER: 9,
  LIMITATION_REACHED: 10,
  INVALID_ARGUMENTS: 11,
  BAD_REQUEST: 12,

  REQUEST_RATE_LIMIT_REACHED: 100,

  //  Users codes 1000-1999
  ACCOUNT_INACTIVE: 1001,
  ACCOUNT_NOT_CONFIRMED: 1002,
  ACCOUNT_CONFIRM_EXPIRED: 1003,
  ACCOUNT_CONFIRMED: 1004,
  ACCOUNT_SOCIAL_AUTH_CANCELED: 1006,
  ACCOUNT_SOCIAL_AUTH_ALREADY_ASSOCIATED: 1007,
  ACCOUNT_TOKEN_ALREADY_EXISTS: 1008,
  ACCOUNT_BAD_PASSWORD_RESET_TOKEN: 1009,
  ACCOUNT_TOKENS_CANNOT_HAVE_ORGANIZATION: 1010,
  ACCOUNT_CREATION_NOT_ALLOWED: 1011,

  //  Project 2000-2999
  PROJECT_NOT_MEMBER: 2001,
  PROJECT_DELETE_DEFAULT: 2002,

  //  Organization 3000-3999
  ORGANIZATION_NOT_MEMBER: 3001,
  ORGANIZATION_ALREADY_EXISTS: 3002,
  ORGANIZATION_MAX_OWNED: 3004,
  ORGANIZATION_MAX_ACTIVE_CREDIT_CARDS: 3005,
  ORGANIZATION_INVALID_VAT: 3006,
  ORGANIZATION_TOKEN_ALREADY_EXISTS: 3050,
  ORGANIZATION_MAX_MEMBER: 3007,
  ORGANIZATION_OWNER_MUST_BE_ADMIN: 3008,
  ORGANIZATION_TOKEN_NOT_ALLOWED: 3009,
  ORGANIZATION_DELETE_DEFAULT: 3010,
  ORGANIZATION_DELETE_PREMIUM_SUBSCRIPTION: 3011,
  ORGANIZATION_TRANSFER_DEFAULT: 3012,

  //  Subscriptions 3100-3199
  SUBSCRIPTION_ALREADY_CANCELED: 3100,
  SUBSCRIPTION_CONFLICT: 3110,
  SUBSCRIPTION_NO_ADDON_PARENT: 3111,
  SUBSCRIPTION_PREV_TRIAL_EXISTS: 3112,
  SUBSCRIPTION_PREV_TRIAL_UNUSED: 3113,
  SUBSCRIPTION_NOT_COVERING_TEST: 3114,

  //  Proxy 3200-3299
  PROXY_FAILED_TO_START: 3200,
  PROXY_FAILED_TO_STOP: 3201,

  //  Notifications 3300-3999
  NOTIFICATION_CONFLICT: 3300,

  //  Tests 4000-4999
  TEST_INVALID_CONFIGURATION: 4000,
  TEST_COPY_NAME_NOT_PROVIDED: 4002,
  TEST_RUN_NOT_ABORTABLE: 4003,
  TEST_RUN_NOT_EXPORTABLE: 4004,
  TEST_RUN_EXPORT_EXISTS: 4005,
  TEST_RUN_MAX_CONCURRENT: 4006,
  TEST_RUN_INSUFFICIENT_FUNDS: 4007,
  TEST_RUN_START_FAILED: 4009,
  TEST_RUN_BLACKLISTED_IP: 4010,
  TEST_RUN_BLACKLISTED_HOST: 4011,
  TEST_RUN_IP_TEST_LIMIT: 4012,
  TEST_RUN_START_MISSING_TEST_ID: 4013,
  TEST_LOAD_ZONE_LIMIT: 4014,
  TEST_RUN_STATUS_NOT_ALLOWED: 4015,

  //  User scenarios 5000-5999
  USER_SCENARIO_NAME_NOT_UNIQUE: 5001,
  USER_SCENARIO_COPY_NAME_NOT_PROVIDED: 5002,
  USER_SCENARIO_DATA_STORE_PERMISSION_DENIED: 5003,
  USER_SCENARIO_INUSE_BY_TEST: 5004,
  USER_SCENARIO_VALIDATIONS_MAX_RUNNING: 5005,

  //  Data files 6000-6999
  DATA_FILE_NAME_NOT_UNIQUE: 6001,
  DATA_FILE_UPLOAD_FAILED: 6002,
  DATA_FILE_CONVERSION_NOT_FINISHED: 6003,
  DATA_FILE_INUSE_BY_TEST: 6004,

  //  Integrations 7000-7999
  INTEGRATIONS_AUTHENTICATION_FAILED: 7001,
  INTEGRATIONS_API_CALL_FAILED: 7002,
  INTEGRATIONS_BAD_RESPONSE: 7003,

  SERVER_METRICS_AGENT_CONFIGURED: 7300,

  //  K6 overlap errors 8008-8999
  //  (the reason for not starting at 8000
  //  is for legacy reasons - errors 0 - 7 are already
  //  the same between api and k6 repos). These are echoed
  //  in k6-lib/constants/errors.py.

  TOO_MANY_REQUESTS: 8008,
  TOO_MANY_ANONYMOUS_TESTS_PER_HOUR: 8009,
  TOO_LONG_ANONYMOUS_TEST_DURATION: 8010,
  TEST_ALREADY_ABORTED: 8011,
  TOO_HIGH_METRICS_RATE: 8012,
  INTERNAL_INFRASTRUCTURE_ERROR: 8013,
  TEST_RUN_INVALID_DURATION: 8014,
  TEST_RUN_NO_DATA_TIMEOUT: 8015,
  TEST_RUN_TIMER_EXCEEDED: 8016,
  SLS_HAR_RECORDER_ERROR: 8017,
  SLS_K6_CONV_ERROR: 8018,
  LOCKDOWN: 8019,
  INTERNAL_NETWORK_ERROR: 8020,
  USER_ALREADY_PROJECT_MEMBER: 8021,
  RECAPTCHA_CHALLENGE_FAILED: 8022,
  RECAPTCHA_CHALLENGE_REQUEST_ERROR: 8023,
  SLS_K6_CONV_SCRIPT_ERROR: 8024,
}
