import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useAppContext } from 'appContext'
import { useBreadcrumbs } from 'breadcrumbsContext'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { useTest } from 'data/useTest'
import { Form } from './components'

export type FormFields = {
  name: string
  script: string
  project_id?: number
  apiError: string
  id: number
}

export const Script = () => {
  const { testId } = useParams<{ testId: string }>()
  const { project } = useAppContext()
  const { setBreadcrumbs } = useBreadcrumbs()

  const isNewTest = testId === 'new'
  const { data: test } = useTest(isNewTest ? null : +testId)

  useEffect(() => {
    if (test === undefined && !isNewTest) {
      return
    }

    setBreadcrumbs([{ name: test?.name || 'New test' }])
  }, [test, setBreadcrumbs, isNewTest])

  if ((!isNewTest && test === undefined) || project === undefined) {
    return <CenteredSpinner />
  }

  return <Form testId={testId} test={test} projectId={project.id} isNewTest={isNewTest} />
}
