import React, { ReactNode } from 'react'
import { Tooltip, Badge } from '@grafana/ui'
import { kebabCase } from 'lodash'

import { useActiveSubsription } from 'data/useSubscriptions'
import { useHasCloudExecution, useCanUserRunTest } from 'data/usePermissions'
import { useK6Url } from 'hooks/useK6Url'
import { ExternalLink } from './ExternalLink'
import { Test } from 'types'
import { isIngestTest } from 'utils/predicate'

type Props = {
  children: (options: { isDisabled: boolean }) => ReactNode
  test?: Test
}

export const RunButtonController = ({ children, test }: Props) => {
  const tooltipPlacement = 'top'

  const canUserRunTest = useCanUserRunTest(test)
  const activeSubscription = useActiveSubsription()
  const hasCloudExecution = useHasCloudExecution()

  const ButtonComponent = children({ isDisabled: !canUserRunTest })

  if (!activeSubscription) {
    return (
      <Tooltip interactive content={<NoSubsription />} placement={tooltipPlacement}>
        <span>{ButtonComponent}</span>
      </Tooltip>
    )
  }

  if (!hasCloudExecution) {
    return (
      <Tooltip interactive content={<NoCloudExec />} placement={tooltipPlacement}>
        <span>{ButtonComponent}</span>
      </Tooltip>
    )
  }

  if (test && isIngestTest(test)) {
    return (
      <Tooltip interactive content={<CliTestRun test={test} />} placement={tooltipPlacement}>
        <span>{ButtonComponent}</span>
      </Tooltip>
    )
  }

  return <>{ButtonComponent}</>
}

const NoSubsription = () => {
  const k6Url = useK6Url()
  return (
    <>
      No active subscription, go to <ExternalLink url={`${k6Url}plans`}>plans</ExternalLink> to upgrade
    </>
  )
}

const NoCloudExec = () => {
  const docsUrl = 'https://k6.io/docs/results-visualization/cloud/'
  return (
    <>
      Subscription does not allow for test runs to be started from the cloud. You can run tests locally and{' '}
      <ExternalLink url={docsUrl}>stream the results</ExternalLink>.
    </>
  )
}

const CliTestRun = ({ test }: { test: Test }) => {
  const name = test.name.includes(' ') ? kebabCase(test.name) : test.name
  const nameWithJSExtension = name.includes('.js') ? name : `${name}.js`
  return (
    <>
      Last test was started from CLI and can not be re-run from the cloud. Here is the CLI command to run the test:{' '}
      <Badge color="purple" text={`k6 run ${nameWithJSExtension} -o cloud`} />
    </>
  )
}
