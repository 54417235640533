import React, { useEffect, useState, FormEvent } from 'react'
import { HorizontalGroup, Icon, Input, Select } from '@grafana/ui'
import { SelectableValue } from '@grafana/data'
import debounce from 'lodash/debounce'

import { TestSortOptions } from 'types'
import { styles } from '../styles'
import { useAppContext } from 'appContext'
import { useBreadcrumbs } from 'breadcrumbsContext'
import { useTests } from 'data/useTests'
import { ButtonTypes, GoToK6Button } from 'components/GoToK6Button'
import { InfiniteScroll } from 'components/InfiniteScroll'
import { TestCard } from './components/TestCard'
import { CreateTestButton } from 'components/CreateTestButton'

const selectOptions = [
  { label: 'Last test run', value: TestSortOptions.LastTestRun },
  { label: 'Created', value: TestSortOptions.Created },
  { label: 'Name', value: TestSortOptions.Name },
]

const SEARCH_WAIT_INTERVAL = 300

export const TestsPage = () => {
  const [searchValue, setSearchValue] = useState('')
  const [sortOption, setSortOption] = useState<TestSortOptions | undefined>(TestSortOptions.LastTestRun)
  const { projectId } = useAppContext()
  const { setBreadcrumbs } = useBreadcrumbs()
  const { tests, isLoading, loadNext } = useTests(projectId, searchValue, sortOption)

  const onSearchValueChanged = (search: string) => {
    setSearchValue(encodeURI(search))
  }

  const onSortOptionChanged = (selectedOption: SelectableValue<TestSortOptions>) => {
    setSortOption(selectedOption.value)
  }

  const debouncedOnInputChange = debounce(onSearchValueChanged, SEARCH_WAIT_INTERVAL)

  useEffect(() => {
    setBreadcrumbs([])
  }, [setBreadcrumbs])

  return (
    <>
      <HorizontalGroup justify={'space-between'}>
        <HorizontalGroup>
          <Input
            prefix={<Icon name="search" />}
            placeholder={'Search by test name'}
            onInput={(e: FormEvent<HTMLInputElement>) => {
              debouncedOnInputChange(e.currentTarget.value)
            }}
          />
          <Select options={selectOptions} value={sortOption} onChange={onSortOptionChanged} />
        </HorizontalGroup>
        <HorizontalGroup>
          <CreateTestButton />
          <GoToK6Button type={ButtonTypes.Projects} id={projectId}></GoToK6Button>
        </HorizontalGroup>
      </HorizontalGroup>
      <br />
      <InfiniteScroll isLoading={isLoading} loadNext={loadNext}>
        <div className={styles.wrapper}>
          {tests.map((test) => (
            <TestCard key={test.id} test={test} />
          ))}
        </div>
      </InfiniteScroll>
    </>
  )
}
