import React, { createContext, useContext, useState, ReactNode } from 'react'
import { useAppContext } from 'appContext'

type Breadcrumb = {
  path?: string
  name: JSX.Element | string
}

type BreadcrumbsContextType = {
  breadcrumbs: Breadcrumb[]
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void
}

type ProviderProps = {
  children: ReactNode
}

const BreadcrumbsContext = createContext<undefined | BreadcrumbsContextType>(undefined)

export const BreadcrumbsContextProvider = ({ children }: ProviderProps) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])
  const { project } = useAppContext()

  const breadcrumbsWithDefaultRoute = [{ path: '/', name: project?.name || 'k6' }, ...breadcrumbs]

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs: breadcrumbsWithDefaultRoute, setBreadcrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  )
}

export const useBreadcrumbs = () => {
  const context = useContext(BreadcrumbsContext)

  if (context === undefined) {
    throw new Error('useBreadcrumbs must be used within a BreadcrumbsContextProvider')
  }

  return context
}
