import useMutation from 'use-mutation'
import { mutate } from 'swr'

import { useDatasource } from 'datasourceContext'

export const useUpdateTest = () => {
  const { ds } = useDatasource()

  return useMutation(ds.updateTest.bind(ds), {
    onSuccess: ({ data }) => {
      // Data needs to be refetched because `ds.updateTest` response does not include test.test_runs
      mutate([data.id, 'test'])
    },
  })
}
