import { RUN_PROCESS, CREATION_PROCESS, Test, TestRun } from 'types'

export const isMultipleOf = (multiple: number, n: number) => n % multiple === 0

export const isNumeric = (n: number | string) => {
  const num = parseFloat(n as string)
  return !isNaN(num) && isFinite(num)
}

export const isCLITest = (test: Test) => {
  const latestRun = test.test_runs[0]
  return latestRun?.id && [RUN_PROCESS.K6_CLOUD, RUN_PROCESS.K6_INGEST].includes(latestRun.run_process)
}

export const isIngestTest = (test: Test) => {
  return test.test_runs[0]?.run_process === RUN_PROCESS.K6_INGEST
}

export const isBuilderTest = (test: Test) => {
  return test.creation_process === CREATION_PROCESS.REQUEST_BUILDER
}

export const isReadOnlyTest = (test: Test) => {
  return isCLITest(test) || isBuilderTest(test) || isSecureTest(test)
}

export const isTestRunnable = (test: Test) => {
  const latestRun = test.test_runs[0]

  const isLastTestRunFromIngest = latestRun?.run_process === RUN_PROCESS.K6_INGEST

  const isTestCreatedCorrectly = [
    CREATION_PROCESS.REQUEST_BUILDER,
    CREATION_PROCESS.SCRIPT_HANDWRITTEN,
    CREATION_PROCESS.SCRIPT_CHROME,
    CREATION_PROCESS.SCRIPT_FIREFOX,
    CREATION_PROCESS.K6_CLOUD,
  ].includes(test.creation_process)

  return isTestCreatedCorrectly && !isLastTestRunFromIngest && !isSecureTest(test)
}

export function isSecureRun(run: TestRun) {
  return run.run_process === RUN_PROCESS.K6_TO_CLOUD_SECURE
}

export function isSecureTest(test: Test) {
  const lastTestRun = test.test_runs[0]
  if (!lastTestRun) {
    return false
  }

  return isSecureRun(lastTestRun)
}
