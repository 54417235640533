import React from 'react'
import { PanelRenderer } from '@grafana/runtime'
import AutoSizer from 'react-virtualized-auto-sizer'
import { PanelChrome } from '@grafana/ui'
import { DataFrame, LoadingState, PanelData, TimeRange } from '@grafana/data'

import { loadFormattedRunsToDataframes, formatRunsForDataFrame } from '../../../utils'
import { styles } from 'pages/styles'
import { TestingChartProps } from './TrendingChart.types'
import { createFieldConfig, createRange, getFieldOverrides, getMaxLoadTime, getRunsToPlot } from './TrendingChart.utils'

const panelOptions = {
  displayMode: 'gradient',
  orientation: 'auto',
  reduceOptions: {
    calcs: ['lastNotNull'],
    fields: '',
    limit: 1000,
    values: true,
  },
  showUnfilled: true,
  text: {
    titleSize: 0,
    valueSize: 0,
  },
}

export const TrendingChart = ({ runs }: TestingChartProps) => {
  const maxLoadTime = getMaxLoadTime(runs)
  const range: TimeRange = createRange(runs)
  const runsToPlot = getRunsToPlot(runs)
  const formattedRuns = formatRunsForDataFrame(runsToPlot)
  const frames: DataFrame[] = loadFormattedRunsToDataframes(formattedRuns)
  const overrides = getFieldOverrides(formattedRuns)

  const panelData: PanelData = {
    state: LoadingState.Done,
    series: [...frames],
    timeRange: range,
  }

  return (
    <div className={styles.chartWrapper}>
      <AutoSizer disableHeight>
        {(size) => (
          <PanelChrome
            title="Trending response time (95th percentile)"
            width={size.width}
            height={300}
            leftItems={[<PanelChrome.LoadingIndicator loading={false} onCancel={() => {}} key="loading-indicator" />]}
          >
            {(innerWidth, innerHeight) => (
              <PanelRenderer
                title="Trending response time (95th percentile)"
                pluginId="bargauge"
                onOptionsChange={() => {}}
                width={innerWidth}
                height={innerHeight}
                data={panelData}
                options={panelOptions}
                fieldConfig={{ ...createFieldConfig(maxLoadTime), overrides }}
              />
            )}
          </PanelChrome>
        )}
      </AutoSizer>
    </div>
  )
}
