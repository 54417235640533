import { useTheme2 } from '@grafana/ui'
import { K6DataSource } from 'datasource/datasource'
import { createTableTheme } from 'pages/styles'
import React from 'react'
import DataTable from 'react-data-table-component'

import { TestRun } from 'types'
import { calculatePrecision } from 'utils/math'
import { separatorFormatter } from 'utils/formatters'
import { useThresholds } from 'data/useThresholds'
import { Status } from '../Status'
import { ThresholdChart } from './ThresholdChart'

interface Props {
  run: TestRun
  ds: K6DataSource
}

const columns = [
  {
    name: '',
    cell: (row: any) => <Status isValid={!row.tainted}></Status>,
    width: '4px',
    compact: true,
  },
  {
    name: '',
    selector: (row: any) => row.name,
  },
  {
    name: '',
    selector: (row: any) => {
      return row.calculated_value
        ? `${row.stat} = ${separatorFormatter(row.calculated_value, calculatePrecision(row.calculated_value))}`
        : '-'
    },
  },
]

export const ThresholdTab = ({ ds, run }: Props) => {
  const { data: thresholds } = useThresholds(run)
  const theme = useTheme2()
  const themeName = theme.isDark ? 'grafana-dark' : ''
  createTableTheme()

  if (!thresholds) {
    // TODO add spinner
    return null
  }

  return (
    <div>
      <DataTable
        columns={columns}
        data={thresholds.value}
        theme={themeName}
        highlightOnHover={true}
        pointerOnHover={true}
        expandableRowsHideExpander
        expandableRows
        expandOnRowClicked
        expandableRowsComponent={ThresholdChart as any}
        expandableRowsComponentProps={{ ds, run }}
        noTableHead
      />
    </div>
  )
}
